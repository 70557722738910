import ctaBanner from "../public/assets/media/cta/backgroundHome.png";
import intro from "../public/assets/media/intro/intro.png";
import introBackground from "../public/assets/media/intro/background.png";

import grinsens from "../public/assets/media/partners/grinsens.png";
import inspace from "../public/assets/media/partners/inspace.png";
import klearcom from "../public/assets/media/partners/klearcom.png";
import lexgo from "../public/assets/media/partners/lexgo.png";
import meetupcall from "../public/assets/media/partners/meetupcall.png";
import sifuture from "../public/assets/media/partners/sifutures.png";
import twilio from "../public/assets/media/partners/twilio.png";
import ujet from "../public/assets/media/partners/ujet.png";

import loadUseCase from "../public/assets/media/useCases/load.svg";
import monitorUseCase from "../public/assets/media/useCases/monitor.svg";
import performanceUseCase from "../public/assets/media/useCases/performance.svg";
import webRTCUseCase from "../public/assets/media/useCases/webrtc.svg";

import resultStatsHome from "../public/assets/media/instructions/statisticsHome.png";
import asserts from "../public/assets/media/instructions/asserts.png";

import browserFeature from "../public/assets/media/features/browsers.svg";
import locationsFeature from "../public/assets/media/features/locations.svg";
import mediaFeature from "../public/assets/media/features/media.svg";
import monitoringFeature from "../public/assets/media/features/monitoring.svg";
import mosFeature from "../public/assets/media/features/mos.svg";
import multiUserFeature from "../public/assets/media/features/multiUser.svg";
import networkFeature from "../public/assets/media/features/network.svg";
import timeExecutionFeature from "../public/assets/media/features/timeExecution.svg";
import validationsFeature from "../public/assets/media/features/validations.svg";
import webrtcMetricsFeature from "../public/assets/media/features/webrtcMetrics.svg";

import performanceMonitoringService from "../public/assets/media/services/performanceMonitoring.png";
import resultValidationService from "../public/assets/media/services/resultValidation.png";
import testCreationService from "../public/assets/media/services/testCreation.png";
import testExecutionService from "../public/assets/media/services/testExecution.png";

const media = {
	ctaBanner,
	features: {
		browserFeature,
		locationsFeature,
		networkFeature,
		mediaFeature,
		monitoringFeature,
		mosFeature,
		multiUserFeature,
		timeExecutionFeature,
		validationsFeature,
		webrtcMetricsFeature
	},
	instructions: { asserts, resultStatsHome },
	intro,
	introBackground,
	partners: {
		grinsens,
		inspace,
		klearcom,
		lexgo,
		meetupcall,
		sifuture,
		twilio,
		ujet
	},
	services: {
		performanceMonitoringService,
		resultValidationService,
		testCreationService,
		testExecutionService
	},
	useCases: { loadUseCase, monitorUseCase, performanceUseCase, webRTCUseCase }
};

export default media;
