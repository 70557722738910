import { FC } from "react";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";
import { StaticImageData } from "next/image";

import Image from "../image/Image";

type Partner = {
	logo: StaticImageData;
	alt: string;
};

interface IProps {
	partners: Partner[];
	t: TFunction;
}

const PartnersV2: FC<IProps> = ({ partners, t }): JSX.Element => {
	return (
		<section className="partners-v2">
			<div className="partners-v2__content">
				{partners.map(({ logo, alt }) => (
					<div key={alt} className="partners-v2__placeholder">
						<Image src={logo} alt={t(alt)} />
					</div>
				))}
			</div>
		</section>
	);
};

export default PartnersV2;
