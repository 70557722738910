import { FC, useContext } from "react";
import Link from "next/link";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import ModalContext from "../../context/modal";

import { Service } from "../../types/types";

import { handleOpenContactUsModal } from "../../utils/method";

import Image from "../image/Image";

interface IProps {
	page: string;
	services: Service[];
	t: TFunction;
}

const Services: FC<IProps> = ({ page, services, t }): JSX.Element => {
	const { setSourcePath, toggleContactUsModal } = useContext(ModalContext);

	const renderService = (service: Service): JSX.Element => {
		const { alt, description, href, link, src, title } = service;

		return (
			<li className="services__service" key={title}>
				<Image src={src} alt={alt} />

				<h3 className="services__service-title">{title}</h3>

				<p className="services__service-description">{description}</p>

				{href ? (
					<Link className="services__link" href={href}>
						{link}
					</Link>
				) : (
					<a
						className="services__link"
						onClick={() =>
							handleOpenContactUsModal(
								toggleContactUsModal,
								setSourcePath,
								page
							)
						}
						tabIndex={0}
						onKeyDown={event =>
							handleOpenContactUsModal(
								toggleContactUsModal,
								setSourcePath,
								page,
								event
							)
						}
					>
						{link}
					</a>
				)}
			</li>
		);
	};

	return (
		<section className="services">
			<div className="services__content">
				<h1 className="services__title">{t(`${page}:SERVICE.TITLE`)}</h1>

				<p className="services__description">{t(`${page}:SERVICE.SUBTITLE`)}</p>

				<ul className="use-cases__list">
					{services.map(service => renderService(service))}
				</ul>
			</div>
		</section>
	);
};

export default Services;
