import { FC } from "react";

import CTABanner from "../components/ctaBanner/CTABanner";
import FeatureListV2 from "../components/featureListV2/FeatureListV2";
import HeadMeta from "../components/headMeta/HeadMeta";
import Instructions from "../components/home/instructions/Instructions";
import Intro from "../components/home/intro/Intro";
import PartnersV2 from "../components/partnersV2/PartnersV2";
import Services from "../components/services/Services";
import UseCases from "../components/useCases/UseCases";

import { pages, translationFiles } from "../utils/const";
import { og } from "../utils/metaTags";

import {
	features,
	instructions,
	partners,
	services,
	useCases
} from "./content";

import media from "./media";
import i18n from "./i18n";

require("./Home.module.scss");

interface IProps {
	setDefaultNavPos: (pos: number) => void;
}

const Home: FC<IProps> = ({ setDefaultNavPos }): JSX.Element => {
	const { HOME } = translationFiles;

	const { t } = i18n;

	const { home } = og;

	return (
		<div className="home">
			<HeadMeta og={home} />

			<Intro
				setHeight={setDefaultNavPos}
				img={media.intro}
				bgImg={media.introBackground}
				i18n={i18n}
			/>

			<PartnersV2 partners={partners} t={t} />

			<UseCases page={HOME} useCases={useCases} t={t} />

			<Instructions instructions={instructions} />

			<div className="home__curvy-bg">
				<FeatureListV2
					title={t("home:FEATURES.TITLE_V2")}
					features={features}
				/>

				<CTABanner
					page={HOME}
					description={t("home:CTA_BANNER.DESCRIPTION")}
					primaryBtnText={t("home:CTA_BANNER.CREATE_ACCOUNT")}
					secondaryBtnText={t("home:CTA_BANNER.CONTACT_SALES")}
					title={t("home:CTA_BANNER.TITLE")}
					imageSrc={media.ctaBanner}
					t={t}
				/>
			</div>

			<Services page={HOME} services={services} t={t} />
		</div>
	);
};

export const getStaticProps = () => ({
	props: {
		name: pages.HOME,
		noGradientNavbar: true
	}
});

export default Home;
