import { Trans } from "react-i18next";

import { pages } from "../utils/const";

import i18n from "./i18n";
import media from "./media";

const useCases = [
	{
		title: i18n.t("home:USE_CASES.CASES.WEBRTC.TITLE"),
		description: i18n.t("home:USE_CASES.CASES.WEBRTC.DESCRIPTION"),
		src: media.useCases.webRTCUseCase,
		alt: i18n.t("common:IMG_ALT.USE_CASES.WEBRTC"),
		link: i18n.t("home:USE_CASES.CASES.WEBRTC.LINK"),
		href: pages.FEATURES_WEB_RTC
	},
	{
		title: i18n.t("home:USE_CASES.CASES.PERFORMANCE.TITLE"),
		description: i18n.t("home:USE_CASES.CASES.PERFORMANCE.DESCRIPTION"),
		src: media.useCases.performanceUseCase,
		alt: i18n.t("common:IMG_ALT.USE_CASES.PERFORMANCE"),
		link: i18n.t("home:USE_CASES.CASES.PERFORMANCE.LINK"),
		href: pages.FEATURES_PERFORMANCE
	},
	{
		title: i18n.t("home:USE_CASES.CASES.LOAD.TITLE"),
		description: i18n.t("home:USE_CASES.CASES.LOAD.DESCRIPTION"),
		src: media.useCases.loadUseCase,
		alt: i18n.t("common:IMG_ALT.USE_CASES.LOAD"),
		link: i18n.t("home:USE_CASES.CASES.LOAD.LINK"),
		href: pages.FEATURES_LOAD
	},
	{
		title: i18n.t("home:USE_CASES.CASES.MONITOR.TITLE"),
		description: i18n.t("home:USE_CASES.CASES.MONITOR.DESCRIPTION"),
		src: media.useCases.monitorUseCase,
		alt: i18n.t("common:IMG_ALT.USE_CASES.MONITOR"),
		link: i18n.t("home:USE_CASES.CASES.MONITOR.LINK"),
		href: pages.FEATURES_MONITORING
	}
];

const features = [
	{
		title: i18n.t("home:FEATURES_V2.LOCATIONS.TITLE"),
		description: i18n.t("home:FEATURES_V2.LOCATIONS.DESCRIPTION"),
		src: media.features.locationsFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.LOCATIONS")
	},
	{
		title: i18n.t("home:FEATURES_V2.NETWORK.TITLE"),
		description: i18n.t("home:FEATURES_V2.NETWORK.DESCRIPTION"),
		src: media.features.networkFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.NETWORK")
	},
	{
		title: i18n.t("home:FEATURES_V2.MOS.TITLE"),
		description: i18n.t("home:FEATURES_V2.MOS.DESCRIPTION"),
		src: media.features.mosFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.MOS")
	},
	{
		title: i18n.t("home:FEATURES_V2.VALIDATIONS.TITLE"),
		description: i18n.t("home:FEATURES_V2.VALIDATIONS.DESCRIPTION"),
		src: media.features.validationsFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.VALIDATIONS")
	},
	{
		title: i18n.t("home:FEATURES_V2.MONITORING.TITLE"),
		description: i18n.t("home:FEATURES_V2.MONITORING.DESCRIPTION"),
		src: media.features.monitoringFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.MONITORING")
	},
	{
		title: i18n.t("home:FEATURES_V2.TIME_EXECUTION.TITLE"),
		description: i18n.t("home:FEATURES_V2.TIME_EXECUTION.DESCRIPTION"),
		src: media.features.timeExecutionFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.TIME_EXECUTION")
	},
	{
		title: i18n.t("home:FEATURES_V2.MULTI_USER.TITLE"),
		description: i18n.t("home:FEATURES_V2.MULTI_USER.DESCRIPTION"),
		src: media.features.multiUserFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.MULTI_USER")
	},
	{
		title: i18n.t("home:FEATURES_V2.BROWSERS.TITLE"),
		description: i18n.t("home:FEATURES_V2.BROWSERS.DESCRIPTION"),
		src: media.features.browserFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.BROWSERS")
	},
	{
		title: i18n.t("home:FEATURES_V2.WEBRTC_METRICS.TITLE"),
		description: i18n.t("home:FEATURES_V2.WEBRTC_METRICS.DESCRIPTION"),
		src: media.features.webrtcMetricsFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.WEBRTC_METRICS")
	},
	{
		title: i18n.t("home:FEATURES_V2.MEDIA.TITLE"),
		description: i18n.t("home:FEATURES_V2.MEDIA.DESCRIPTION"),
		src: media.features.mediaFeature,
		alt: i18n.t("common:IMG_ALT.FEATURES.MEDIA_INJECTION")
	}
];

const services = [
	{
		title: i18n.t("home:SERVICE.TEST_EXECUTION.TITLE"),
		description: i18n.t("home:SERVICE.TEST_EXECUTION.DESCRIPTION"),
		src: media.services.testExecutionService,
		alt: i18n.t("common:IMG_ALT.SERVICES.TEST_EXECUTION"),
		link: i18n.t("home:SERVICE.TEST_EXECUTION.LINK"),
		href: pages.SERVICES_LOAD_TESTING
	},
	{
		title: i18n.t("home:SERVICE.PERFORMANCE.TITLE"),
		description: i18n.t("home:SERVICE.PERFORMANCE.DESCRIPTION"),
		src: media.services.performanceMonitoringService,
		alt: i18n.t("common:IMG_ALT.SERVICES.PERFORMANCE"),
		link: i18n.t("home:SERVICE.PERFORMANCE.LINK"),
		href: pages.PERFORMANCE_MONITORING
	},
	{
		title: i18n.t("home:SERVICE.TEST_CREATION.TITLE"),
		description: i18n.t("home:SERVICE.TEST_CREATION.DESCRIPTION"),
		src: media.services.testCreationService,
		alt: i18n.t("common:IMG_ALT.SERVICES.TEST_CREATION"),
		link: i18n.t("home:SERVICE.TEST_CREATION.LINK"),
		href: pages.SERVICES_TEST_CREATION
	},
	{
		title: i18n.t("home:SERVICE.VALIDATION.TITLE"),
		description: i18n.t("home:SERVICE.VALIDATION.DESCRIPTION"),
		src: media.services.resultValidationService,
		alt: i18n.t("common:IMG_ALT.SERVICES.VALIDATION"),
		link: i18n.t("home:SERVICE.VALIDATION.LINK"),
		href: pages.TEST_RESULT_VALIDATION
	}
];

const partners = [
	{
		logo: media.partners.meetupcall,
		alt: i18n.t("common:IMG_ALT.PARTNERS.MEETUPCALL")
	},
	{
		logo: media.partners.twilio,
		alt: i18n.t("common:IMG_ALT.PARTNERS.TWILIO")
	},
	{ logo: media.partners.ujet, alt: i18n.t("common:IMG_ALT.PARTNERS.UJET") },
	{
		logo: media.partners.inspace,
		alt: i18n.t("common:IMG_ALT.PARTNERS.INSPACE")
	},
	{ logo: media.partners.lexgo, alt: i18n.t("common:IMG_ALT.PARTNERS.LEXGO") },
	{
		logo: media.partners.grinsens,
		alt: i18n.t("common:IMG_ALT.PARTNERS.GRINSENS")
	},
	{
		logo: media.partners.sifuture,
		alt: i18n.t("common:IMG_ALT.PARTNERS.SIFUTURE")
	},
	{
		logo: media.partners.klearcom,
		alt: i18n.t("common:IMG_ALT.PARTNERS.KLEARCOM")
	}
];

const instructions = [
	{
		img: media.instructions.resultStatsHome,
		imgAlt: "IMG_ALT.INSTRUCTIONS.ANALYZE",
		text: i18n.t("home:INSTRUCTIONS.ANALYZE.DESCRIPTION"),
		title: (
			<Trans i18nKey="INSTRUCTIONS.ANALYZE.TITLE">
				Analyze metrics <span> in-depth </span>
			</Trans>
		),
		right: true
	},
	{
		img: media.instructions.asserts,
		imgAlt: i18n.t("common:IMG_ALT.INSTRUCTIONS.GET_NOTIFIED"),
		text: i18n.t("home:INSTRUCTIONS.ANALYZE.DESCRIPTION"),
		title: i18n.t("home:INSTRUCTIONS.GET_NOTIFIED.TITLE"),
		right: false
	}
];

export { features, instructions, partners, services, useCases };
