// eslint-disable-next-line
import { createInstance, i18n as I18n } from "i18next";

import common from "../translations/eng/common.json";
import home from "../translations/eng/home.json";

export const resources = {
	"en-US": {
		common,
		home
	} as const
};

const i18n: I18n = createInstance(
	{
		debug: false,
		defaultNS: "common",
		fallbackLng: "en-US",
		lng: "en-US",
		react: {
			bindI18n: "languageChanged loaded",
			nsMode: "default",
			bindI18nStore: false
		},
		ns: ["common", "home"],
		resources
	},
	err => {
		if (err) return console.log("something went wrong loading", err);
	}
);

export default i18n;
