import { FC } from "react";

import { FeatureItemV2 } from "../../types/types";

import Image from "../image/Image";

interface IProps {
	features: FeatureItemV2[];
	title: string;
}

const FeatureListV2: FC<IProps> = ({ features, title }): JSX.Element => {
	const renderFeature = (feature: FeatureItemV2): JSX.Element => {
		const { alt, description, src, title: featureTitle } = feature;

		return (
			<li className="feature-list-v2__feature" key={featureTitle}>
				<div className="feature-list-v2__feature-title-section">
					<Image src={src} alt={alt} />

					<h3 className="feature-list-v2__feature-title">{featureTitle}</h3>
				</div>

				<p className="feature-list-v2__feature-description">{description}</p>
			</li>
		);
	};

	return (
		<section className="feature-list-v2">
			<div className="feature-list-v2__content">
				<h1 className="feature-list-v2__title">{title}</h1>

				<ul className="feature-list-v2__list">
					{features.map(feature => renderFeature(feature))}
				</ul>
			</div>
		</section>
	);
};

export default FeatureListV2;
