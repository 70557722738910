import { FC, useContext } from "react";
import Link from "next/link";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import ModalContext from "../../context/modal";

import { handleOpenContactUsModal } from "../../utils/method";

import Image from "../image/Image";

type UseCase = {
	alt: string;
	description: string;
	link: string;
	src: string;
	title: string;
	href?: string;
};

interface IProps {
	page: string;
	useCases: UseCase[];
	t: TFunction;
}

const UseCases: FC<IProps> = ({ page, t, useCases }): JSX.Element => {
	const { setSourcePath, toggleContactUsModal } = useContext(ModalContext);

	const renderCase = (useCase: UseCase): JSX.Element => {
		const { alt, description, href, link, src, title } = useCase;

		return (
			<li className="use-cases__use-case" key={title}>
				<Image src={src} alt={alt} />

				<h3 className="use-cases__use-case-title">{title}</h3>

				<p className="use-cases__use-case-description">{description}</p>

				{href ? (
					<Link className="use-cases__link" href={href}>
						{link}
					</Link>
				) : (
					<a
						className="use-cases__link"
						onClick={() =>
							handleOpenContactUsModal(
								toggleContactUsModal,
								setSourcePath,
								page
							)
						}
						tabIndex={0}
						onKeyDown={event =>
							handleOpenContactUsModal(
								toggleContactUsModal,
								setSourcePath,
								page,
								event
							)
						}
					>
						{link}
					</a>
				)}
			</li>
		);
	};

	return (
		<section className="use-cases">
			<div className="use-cases__content">
				<h1 className="use-cases__title">{t("home:USE_CASES.TITLE")}</h1>

				<p className="use-cases__description">
					{t("home:USE_CASES.DESCRIPTION")}
				</p>

				<ul className="use-cases__list">
					{useCases.map(useCase => renderCase(useCase))}
				</ul>
			</div>
		</section>
	);
};

export default UseCases;
