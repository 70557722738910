import { FC, useContext } from "react";
import { StaticImageData } from "next/image";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import { Button } from "ui-kit";

import ModalContext from "../../context/modal";

import { pages } from "../../utils/const";
import {
	classNames,
	handleOpenContactUsModal,
	navigate
} from "../../utils/method";

import Image from "../image/Image";

interface IProps {
	description?: string;
	page: string;
	primaryBtnText: string;
	secondaryBtnText: string;
	title: string;
	imageSrc: StaticImageData;
	t: TFunction;
}

const CTABanner: FC<IProps> = ({
	description,
	page,
	primaryBtnText,
	secondaryBtnText,
	title,
	imageSrc,
	t
}): JSX.Element => {
	const { setSourcePath, toggleContactUsModal } = useContext(ModalContext);

	return (
		<section
			className={classNames(["cta-banner-v2", `cta-banner-v2--${page}`])}
		>
			<div
				className={classNames([
					"cta-banner-v2__content",
					`cta-banner-v2__content--${page}`
				])}
			>
				<Image
					src={imageSrc}
					alt={t("common:IMG_ALT.BACKGROUND")}
					className="cta-banner-v2__content-bg-image"
					fill
					quality={90}
					sizes="100vw"
				/>

				<h1 className="cta-banner-v2__title">{title}</h1>

				{description && (
					<p className="cta-banner-v2__description">{description}</p>
				)}

				<div
					className={classNames([
						"cta-banner-v2__buttons",
						`cta-banner-v2__buttons--${page}`
					])}
				>
					<Button
						href={navigate(pages.REGISTER)}
						className="cta-banner-v2__button cta-banner-v2__button--primary"
					>
						{primaryBtnText}
					</Button>

					<Button
						targetBlank
						className="cta-banner-v2__button cta-banner-v2__button--secondary"
						brand="secondary"
						onClick={() =>
							handleOpenContactUsModal(
								toggleContactUsModal,
								setSourcePath,
								page
							)
						}
					>
						{secondaryBtnText}
					</Button>
				</div>
			</div>
		</section>
	);
};

export default CTABanner;
