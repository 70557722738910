import { FC } from "react";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import { Button } from "ui-kit";

import { links, pages } from "../../../../utils/const";
import { navigate } from "../../../../utils/method";

interface IProps {
	t: TFunction;
}

const Trial: FC<IProps> = ({ t }): JSX.Element => {
	return (
		<div className="trial">
			<form className="trial__form">
				<Button href={navigate(pages.REGISTER)} className="button--trial">
					{t("common:NAV.TRY_FOR_FREE")}
				</Button>

				<Button
					href={links.PIPEDRIVE.SCHEDULE_MEETING}
					targetBlank
					className="button--demo"
					brand="secondary"
				>
					{t("common:NAV.BOOK_DEMO")}
				</Button>
			</form>
		</div>
	);
};

export default Trial;
