import { FC, useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import { StaticImageData } from "next/image";
// eslint-disable-next-line
import { i18n as I18n } from "i18next";

import Image from "../../image/Image";

import Trial from "./trial/Trial";

interface IProps {
	bgImg: StaticImageData;
	img: StaticImageData;
	setHeight: (height: number) => void;
	i18n: I18n;
}

const Intro: FC<IProps> = ({ bgImg, img, setHeight, i18n }): JSX.Element => {
	const { t } = i18n;
	const elemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setTimeout(() => {
			if (elemRef.current) {
				setHeight(elemRef.current.clientHeight);
			}
		}, 100);
	}, [setHeight]);

	return (
		<div className="intro" ref={elemRef}>
			<Image
				src={bgImg}
				fill
				alt={t("common:IMG_ALT.BACKGROUND")}
				className="intro__bg"
				priority
			/>

			<div className="intro__content">
				<div className="intro__text">
					<h1 className="intro__title">
						<Trans i18nKey={"home:TITLE"} i18n={i18n}>
							Scale your web application confidently
							<span>with Loadero!</span>
						</Trans>
					</h1>

					<p className="intro__subtitle">{t("home:SUBTITLE")}</p>

					<Trial t={t} />
				</div>

				<div className="intro__image-container">
					<Image src={img} alt={t("common:IMG_ALT.INTRO_SCREEN")} />
				</div>
			</div>
		</div>
	);
};

export default Intro;
