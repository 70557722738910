import { FC } from "react";
import { StaticImageData } from "next/image";

import Instruction from "../../instruction/Instruction";

type Instruction = {
	img: StaticImageData;
	imgAlt: string;
	right: boolean;
	text: string;
	title: string | JSX.Element;
};

interface IProps {
	instructions: Instruction[];
}

const Instructions: FC<IProps> = ({ instructions }): JSX.Element => (
	<div className="instructions">
		<div className="instructions__content">
			{instructions.map(({ title, text, img, imgAlt, right }) => (
				<Instruction
					key={imgAlt}
					title={title}
					text={text}
					img={img}
					imgAlt={imgAlt}
					right={right}
				/>
			))}
		</div>
	</div>
);

export default Instructions;
