import { FC } from "react";
import { StaticImageData } from "next/image";

import { Button } from "ui-kit";

import type {
	IProps as ButtonProps,
	ButtonType
} from "ui-kit/src/components/button/Button";

import { classNames } from "../../utils/method";

import Image from "../image/Image";

interface IProps {
	bg?: string | StaticImageData;
	bgAlt?: string;
	buttonProps?: ButtonProps & ButtonType;
	img: string | StaticImageData;
	imgAlt: string;
	page?: string;
	right?: boolean;
	text: string;
	title: string | JSX.Element;
}

const Instruction: FC<IProps> = ({
	bg,
	bgAlt,
	buttonProps,
	img,
	imgAlt,
	page,
	right,
	text,
	title
}): JSX.Element => (
	<div className={classNames(["instruction", right && "instruction--right"])}>
		<div className="instruction__images">
			{bg && bgAlt && (
				<Image
					src={bg}
					alt={bgAlt}
					className="instruction__image-bg"
					width={468}
					height={256}
					quality={20}
				/>
			)}

			<Image
				src={img}
				alt={imgAlt}
				className={classNames([
					"instruction__image",
					`instruction__image--${page}`
				])}
			/>
		</div>

		<div className="instruction__info">
			<h3 className="instruction__title">{title}</h3>

			<div className="instruction__description">{text}</div>

			{buttonProps?.children && (
				<Button {...buttonProps}>{buttonProps.children}</Button>
			)}
		</div>
	</div>
);
export default Instruction;
